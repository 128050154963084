<template>
  <div class="Omeprazol">
    <div
        class="game-wrapper"
        :class="[
        { stage1: stage === 1 },
        { stage2: stage === 2 },
        { stage3: stage === 3 },
        { stage4: stage === 4 },
        { stage5: stage === 5 },
        { stage6: stage === 6 },
        { stage7: stage === 7 },
        { stage8: stage === 8 },
        { stage9: stage === 9 },
      ]"
    >

      <div class="game-wrapper__stage " v-show="stage === 1">

        <div class="game-wrapper__stage--white-block stage1">
          <p class="game-wrapper__stage--text  green">
            <span>Яку там помпу він сказав<br>тобі заблокувати?</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-omeprazol/stage-1-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(2)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 2">

        <div class="game-wrapper__stage--white-block stage2">
          <p class="game-wrapper__stage--text desk green">
            <span>Нейтронну чи протонну, щось таке.<br>Зараз покажемо призначення фармацевту</span></p>

          <p class="game-wrapper__stage--text mobile green">
            <span>Нейтронну чи протонну, щось таке. Зараз покажемо призначення фармацевту</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-omeprazol/stage-2-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(3)">
          <p>
            До аптеки
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 3">

        <div class="game-wrapper__stage--white-block stage3">
          <p class="game-wrapper__stage--text  blue">
            <span>Вітаю у нашій аптеці!<br>Чим можу Вам допомогти?</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-omeprazol/stage-3-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(4)">
          <p>
            До симптомів
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 4">

        <div class="game-wrapper__stage--white-block stage4">
          <p class="game-wrapper__stage--text  green">
            <span>Ось рецепт від лікаря, всередині мене потрібно приборкати протонну помпу!</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-omeprazol/stage-4-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(5)">
          <p>
            Переглянути
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 5">

        <div class="game-wrapper__stage--white-block popup stage5">
          <img src="../../assets/img/game-omeprazol/stage-5-paper.png" alt="">

          <div class="game-wrapper__stage--btn-next-stage small-m hover_type6" @click="nextStage(6)">
            <p>Далі</p>
          </div>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 6">

        <div class="game-wrapper__stage--white-block stage6">
          <p class="game-wrapper__stage--text  blue">
            Вам потрібен засіб, що знизить рівень кислотності в шлунку.<br>
            <span>Раджу Вам Омепразол-Тева!</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-omeprazol/stage-6-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(7)">
          <p>
            До симптомів
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 7">

        <div class="game-wrapper__stage--white-block stage7">
          <p class="game-wrapper__stage--text  green">
            <span>Чудово! Знизьмо рівень кислоти у шлунку!</span>
          </p>
          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-omeprazol/stage-7-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(8)">
          <p>
            Переглянути
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 8">

        <div class="game-wrapper__stage--white-block popup custom-p">

          <div class="game-wrapper__stage--top-text">
            Омепразол-Тева – специфічний інгібітор протонної помпи парієтальних клітин. Завдяки його дії зменшується
            утворення кислоти в шлунку, що дає змогу знизити її подразнюючий вплив.
          </div>

          <div class="game-wrapper__stage--bottom-text">
            Чи вдасться Вам пригнічити вироблення кислоти парієтальними клітинами, щоб знизити рівень кислотності в
            шлунку? Перевірмо!
          </div>

          <div class="game-wrapper__stage--btn-next-stage hover_type6 auto-m" @click="nextStage(9)">
            <p>Вперед!</p>
          </div>
        </div>

      </div>

      <OmeprazolGame v-if="stage === 9"></OmeprazolGame>
    </div>

    <div class="notes-wrapper" :class="{ active:  stage === 5 ||stage === 8   }" v-show="stage !== 9">


      <span>
     Інформація про лікарський засіб. РП №UA/15152/01/01, №UA/15152/01/02. Для професійної діяльності медичних та фармацевтичних працівників. Омепразол-Тева (1 капсула містить омепразолу 20мг або 40мг у складі гастрорезистентних капсул). Характеристики: капсули гастрорезистентні тверді. 20мг: синьо-помаранчеві капсули №2 зі штампом «О» на кришечці і «20» на корпусі, що містять мікрогранули біло-бежевого кольору. 40мг: синьо-помаранчеві капсули №0 зі штампом «О» на кришечці і «40» на корпусі, що містять мікрогранули біло-бежевого кольору. Лікувальні властивості: засіб для лікування пептичної виразки і гастроезофагеальної рефлюксної хвороби. Інгібітор протонної помпи. Найбільш частими побічними реакціями є: головний біль, абдомінальний біль, запор, діарея, метеоризм та нудота/блювання.
      </span>

      <span>
      Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284
      </span>
      <span>CBG-UA-00826. Термін дії матеріалу – 23.08.2024.</span>
    </div>

    <img
        v-if="
        stage === 1 || stage === 2 || stage === 3 || stage === 4 || stage === 6 || stage === 7|| stage === 9
      "
        class="bg"
        src="../../assets/img/game-omeprazol/bg.png"
        alt=""
    />

    <img
        v-else
        class="bg"
        src="../../assets/img/game-clopidogrel/bg-type-2.jpg"
        alt=""
    />
  </div>
</template>

<script>
import OmeprazolGame from "../../components/Omeprazol-game.vue";

export default {
  name: "Omeprazol",

  data: () => ({
    stage: 1,
  }),

  components: {
    OmeprazolGame,
  },

  methods: {
    nextStage(stage) {
      this.stage = stage;
      window.scrollTo(0, 0)

    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";


.Omeprazol {
  position: relative;
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));
  height: 100%;

  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .game-wrapper {
    height: 100%;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;

    padding: 40px 20px;

    &.stage9 {
      padding: 0;
    }

    &__stage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;

      &--white-block {
        max-width: 610px;
        width: 100%;

        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 20px;
        border-radius: 14px;

        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;

        &.bottom {
          top: initial;
          bottom: 0;
        }

        &.custom-p {
          padding: 0;
          padding-bottom: 35px;
        }

        &.popup {
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0;

          img {
            max-width: 280px;
            margin: 0 auto;
            width: 100%;
            height: auto;
            display: block;
          }
        }

        &.stage3 {
          img {
            max-width: 280px;
            width: 100%;
          }
        }
      }

      &--text {
        font-size: 20px;
        line-height: 120%;
        font-family: $M;
        text-align: center;

        max-width: 610px;
        width: 100%;

        span {
          font-family: $EB;
        }

        &.mobile {
          display: none;
        }

        &.green {
          color: $green;
        }

        &.blue {
          color: $blue;
        }
      }

      &--img-circle {
        position: relative;
        max-width: 100%;
        width: 100%;

        @media screen and (max-width: 425px) {
          max-width: 274px;        }

        img {
          margin: 0 auto;

          position: relative;
          z-index: 1;

          max-width: 358px;
          width: 100%;
          height: auto;

          display: block;

          margin-top: 40px;

          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
          border-radius: 50%;
          @media screen and (max-width: 425px) {
            max-width: 100%;
          }
        }
      }

      &--btn-next-stage {
        border: 2px solid $green;
        background: white;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;

        margin-top: 50px;
        padding: 14px 15px;

        max-width: 230px;
        width: 100%;

        cursor: pointer;

        &.small-m {
          margin-top: 30px;
        }

        &.small-p {
          padding-top: 2px;
          padding-bottom: 3px;

        }

        &.auto-m {
          margin: auto 0 0 0;
        }

        p {
          color: $green;

          font-family: $EB;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
        }
      }

      &--popup-text {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        line-height: 120%;
        font-family: $M;
        color: $grey;

        span {
          font-family: $EB;
        }
      }

      &--arrow-down {
        position: absolute;
        bottom: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 10px solid white;
      }

      &--top-text {
        background: $teal;
        padding: 80px 30px 75px 30px;
        text-align: center;

        font-family: $M;
        font-size: 24px;
        line-height: 120%;
        color: white;
      }

      &--bottom-text {
        color: #424242;
        font-size: 20px;
        line-height: 120%;

        font-family: $M;

        padding: 53px 30px 49px 30px;
        text-align: center;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .Omeprazol {

    .bg {

    }

    .game-wrapper {
      padding: 20px 20px 0 20px;

      &.stage3 {
      }

      &__stage {

        &--white-block {
          height: 90px;
          padding: 10px;


          &.bottom {
            height: 70px;
          }

          &.stage5 {
            height: auto;
          }

          &.popup {
            padding-bottom: 17px;
            height: auto;

            img {
              max-width: 274px;            }
          }
        }

        &--text {
          font-size: 18px;
          line-height: 20px;

          &.mobile {
            display: block;
          }

          &.desk {
            display: none;
          }
        }

        &--img-circle {

          margin-top: 40px;

          &.mr-top {
            margin-top: 0;

            img {
              margin-top: 0;
            }
          }

        }

        &--btn-next-stage {

          margin-top: 25px;
          height: 50px;

          &.small-p {

          }

          &.auto-m {
          }

          p {
            font-size: 16px;
            line-height: 22px;

          }
        }

        &--arrow-down {

        }

        &--top-text {
          font-size: 16px;
          line-height: 120%;
          padding: 28px 20px 23px 20px;
        }

        &--bottom-text {
          font-size: 14px;
          line-height: 120%;
          padding: 31px 20px 38px 20px;
        }
      }
    }

    .notes-wrapper {

      padding-top: 23px;
      font-size: 8px;
      line-height: 8px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }
}

@media screen and (max-width: 600px) {
}
</style>
